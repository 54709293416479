angular.module('app').service('weatherService', function (_logicService, _calendar, $q, _view) {
    this.buildPrecipitationBars = function (columns, $scope) {
        $scope.w.missingPrecipAmount = []; //column empty precipAmount flag
        var missingPrecipAmountDetailed = new Set(); //generated detailed columns

        //fill missing c.precipAmountDetailed
        columns.forEach(c => {
            if (_.isUndefined(c.precipAmount) || c.calendarItem == null) {
                $scope.w.missingPrecipAmount.push(true);
            } else {
                $scope.w.missingPrecipAmount.push(false);

                if (_calendar.granularity == 'h') {
                    c.precipAmountDetailed = {};
                    c.precipAmountDetailed[c.timestamp] = c.precipAmount;
                } else { //d,w,m,s granularity

                    /**
                     * Fill in missing c.precipAmountDetailed
                     * c.precipAmountDetailed is based on c.precipAmount
                     */
                    var precipAmountDetailed = c.precipAmountDetailed || {};
                    var granularityAmount = 24; //expected number of elements in precipAmountDetailed
                    var averagePrecipAmount;

                    if (_calendar.granularity != 'd') { //w,m,s granularity
                        granularityAmount = c.calendarItem.dateFinish.diff(c.calendarItem.date, "days") + 1;
                    }

                    if (!(_.isEmpty(precipAmountDetailed))) {
                        var precipAmountDetailedSum = _.reduce(precipAmountDetailed, (a, b) => a + b);
                        averagePrecipAmount = _logicService.round(Math.max(0, c.precipAmount - precipAmountDetailedSum) / granularityAmount);

                        // c.precipAmountDetailed has empty fields
                        if (_.size(precipAmountDetailed) != granularityAmount) {
                            _.each(_.range(granularityAmount), function (i) {
                                setAverageValueToTheRest(i, precipAmountDetailed, averagePrecipAmount);
                            });
                        }
                    } else {
                        averagePrecipAmount = _logicService.round(Math.max(0, c.precipAmount) / granularityAmount);
                        _.each(_.range(granularityAmount), function (i) {
                            setAverageValueToTheRest(i, precipAmountDetailed, averagePrecipAmount);
                        });
                    }

                    function setAverageValueToTheRest(value, precipitation, average) {
                        var date = _calendar.granularity === 'd' ? moment.utc(c.timestamp).add(value, 'hour') : moment.utc(c.timestamp).add(value, 'day');
                        var selector = date.format(_logicService.DATETIME_FORMAT);
                        if (_.isUndefined(precipitation[selector])) {
                            precipitation[selector] = average;
                            missingPrecipAmountDetailed.add(selector);
                        } else {
                            precipitation[selector] = precipitation[selector];
                        }
                    }

                    //group day granularity to 3-hours intervals
                    if (_calendar.granularity == 'd') {
                        for (i=0; i < 24; i=i+3) {
                            var date1 = moment.utc(c.timestamp).add(i, 'hour');
                            var date2 = moment.utc(c.timestamp).add(i+1, 'hour');
                            var date3 = moment.utc(c.timestamp).add(i+2, 'hour');
                            var selector1 = date1.format(_logicService.DATETIME_FORMAT);
                            var selector2 = date2.format(_logicService.DATETIME_FORMAT);
                            var selector3 = date3.format(_logicService.DATETIME_FORMAT);
                            precipAmountDetailed[selector1] += (precipAmountDetailed[selector2] + precipAmountDetailed[selector3]); //add next and next column
                            delete precipAmountDetailed[selector2];
                            delete precipAmountDetailed[selector3];
                            if (missingPrecipAmountDetailed.has(selector2) || missingPrecipAmountDetailed.has(selector3)) { //check if one of next column was generated
                                missingPrecipAmountDetailed.add(selector1);
                            }
                        }
                    }

                    c.precipAmountDetailed = precipAmountDetailed;
                }
            }
        });

        //precipitations scale max value, in millimeters
        var precipitationsInBrazil = {
            s: 60,
            m: 80,
            w: 60,
            d: 20,
            h: 16
        };

        var precipitationsScale = [0];
        for (var i = 1; i < 5; i++) {
            precipitationsScale[i] = _logicService.round((precipitationsInBrazil[_calendar.granularity] / 5) + precipitationsScale[i-1], 0);
        }
        precipitationsScale[5] = precipitationsInBrazil[_calendar.granularity];
        precipitationsScale = precipitationsScale.map(item => _logicService.round(item, 0));
        precipitationsScale.reverse();
        $scope.w.precipitationsScale = precipitationsScale;

        var promisesArray = [];
        _.each(columns, function(c) {
            _.each(c.precipAmountDetailed, function(value, key) {
                var date = moment.utc(key);
                promisesArray.push(_calendar.getPositionForDatePromise(date));
                date = date.clone();
                if (_calendar.granularity == 'h') {
                    date.add(1, 'h');
                } else if(_calendar.granularity == 'd') {
                    date.add(3, 'h');
                } else {
                    date.add(1, 'd');
                }
                date.subtract(1, "ms");
                promisesArray.push(_calendar.getPositionForDatePromise(date));
            });
        });

        $scope.w.precipitations = [];  //{width, height, color, x, y, value}
        $q.all(promisesArray).then(function(positions) {
            var i = 0;
            _.each(columns, function(c) {
                _.each(c.precipAmountDetailed, function(value, key) {
                    var chartColumn = {};
                    chartColumn.x = positions[i * 2];
                    chartColumn.width = positions[i * 2 + 1] - chartColumn.x;
                    chartColumn.height = (100 * value) / precipitationsInBrazil[_calendar.granularity];
                    chartColumn.y = 100 - chartColumn.height;
                    chartColumn.value = _view.getCurrentUser().units == 'METRIC' ? _logicService.round(value, 2) : _logicService.round(value * 0.0393701, 2);
                    chartColumn.moment = moment.utc(key);
                    if (missingPrecipAmountDetailed.has(key)) { //generated column
                        chartColumn.strokeWidth = 2;
                        chartColumn.strokeColor = '#f00';
                        chartColumn.strokeOpacity = 0.5;
                    } else { //real column
                        chartColumn.strokeWidth = 2;
                        chartColumn.strokeColor = '#745E22';
                        chartColumn.strokeOpacity = 0.5;
                    }

                    $scope.w.precipitations.push(chartColumn);
                    i++;
                });
            });
        });
    }
});